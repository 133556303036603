<template>

  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isDuplicateShiftSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-duplicate-shift-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Duplicate Week
          </h5>

          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />

        </div>
        <div class="p-2">
          <b-row>
            <b-col md="12">
              <p>All shifts will be duplicated as drafts. To make them visible to staff, you will need to publish rota.
              </p>
            </b-col>
          </b-row>
          <validation-observer ref="duplicateShift">
            <b-form ref="duplicate_shift" @submit.prevent>
              <b-row>
                <b-col md="12">
                  <p class="font-weight-bold font-weight-700">Copy from:</p>
                  <validation-provider
                      #default="{ errors }"
                      name="Copy from"
                      rules="required"
                  >
                    <v-select
                        v-model="selectedCopyFrom"
                        :options="copyFromOptions"
                        label="text"
                        placeholder="Select week"
                        value="id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="mt-1" md="12">
                  <p class="font-weight-bold font-weight-700">Paste to:</p>
                </b-col>
              </b-row>

              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Paste to"
                    rules="required"
                >
                  <v-select
                      v-model="selectedPastTo"
                      :options="copyToOptions"
                      label="text"
                      multiple
                      placeholder="Select week(s)"
                      value="id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-row class="mt-1" v-if="selectedCopyFrom && selectedShiftCount">
                <b-col md="12" >
                  <b-alert
                      show
                      variant="success"
                      class="w-100"
                  >
                    <div class="alert-body text-black-50">
                      Total shift drafts to be created: {{selectedShiftCount}}
                    </div>
                  </b-alert>
                </b-col>

              </b-row>
              <b-row>


                <b-col md="12">
                  <div class="flex">
                    <b-form-checkbox
                        v-model="shift_with_allocated_staff"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                    >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" style="color: white" />
        </span>
                      <span class="switch-icon-right">
          <feather-icon icon="XIcon" style="color: rgba(8, 4, 51, 0.5);" />
        </span>
                    </b-form-checkbox>

                    <p>Copy vacant shifts only</p>
                  </div>
                </b-col>

              </b-row>

            </b-form>
          </validation-observer>
          <div class="flex mt-1">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="duplicateFormSubmit()"
            >
              Save
            </b-button>
            <b-button
                v-ripple.400="'rgb(179,179,179)'"
                class="ml-2"
                @click="isDuplicateShiftSidebarActive = false"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormTextarea,
  BLink,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BRow,
  BSidebar,
  VBModal,
  VBToggle
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import MomentMixin from '@/mixins/MomentMixin'
import shift from '@/apis/modules/shift'
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
  name: 'duplicateSideBar',
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BFormGroup,
    BButton,
    BSidebar,
    BFormCheckbox,
    BFormTextarea,
    flatPickr,
    BAlert,
    ValidationObserver,
    ValidationProvider,
    BOverlay
  },
  mixins: [MomentMixin],
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal
  },
  data () {
    return {
      query: {
        value: '',
        start: null,
        end: null
      },
      loading: false,
      copyFromOptions: [],
      copyToOptions: [],
      selectedCopyFrom: null,
      selectedPastTo: null,
      shift_with_allocated_staff: false,
      selectedRange: '',
      dateOption: [],
      data: '',
      selected: '',
      timePicker: '',
      selectedMultiple: '',

      copy_to_start: null,
      copy_to_end: null,
      paste_to_start: null,
      paste_to_end: null,
      selectedShiftCount : null

    }
  },
  model: {
    prop: 'isDuplicateShiftSidebarActive',
    event: 'update:is-duplicate-shift-sidebar-active'
  },
  props: {
    isDuplicateShiftSidebarActive: {
      default: false,
      type: Boolean,
      required: true
    },
    cellData: {
      type: Object,
      required: true
    }
  },
  watch: {
    copy_to_end () {
      if (this.copy_to_start && this.copy_to_end) this.makeDropDown()
    },
    copy_to_start () {
      if (this.copy_to_start && this.copy_to_end) this.makeDropDown()
    },
    cellData () {
      this.data = this.cellData
    },
    isDuplicateShiftSidebarActive (isSideBarVisible) {
      this.resetForm()
      if (isSideBarVisible && this.copyFromOptions.length === 0) {
        this.makeDuplicateCopyFromDropdown()
        this.makeDuplicatePastToDropdown()
      }
    },
    selectedCopyFrom (value) {
      if (value) this.getSelectedShiftCount(value)
    }
  },
  methods: {
    duplicateShifts () {

    },
    makeDropDown () {
      if (this.copy_to_start !== null && this.copy_to_end !== null) {
        const start = moment(this.copy_to_start + ' ' + '00:00:00')
        const end = moment(this.copy_to_end + ' ' + '00:00:00')

        const diffDays = ((end.diff(moment(start), 'days')))
        this.dateOption = []
        let start_new = moment(this.copy_to_end + ' ' + '00:00:00').utc().add('days', 2).utc().format('YYYY-MM-DD')
        let end_new = moment(this.copy_to_end + ' ' + '00:00:00').utc().add('days', 2 + diffDays).utc().format('YYYY-MM-DD')
        for (var i = 1; i < 7; i++) {

          const payload = {
            start: moment(start_new).format('YYYY-MM-DD HH:mm:ss '),
            end: moment(end_new).format('YYYY-MM-DD HH:mm:ss'),
            text: `${this.momentFormat(start_new, 'DD-MM-YYYY  ')} -
             ${this.momentFormat(end_new, 'DD-MM-YYYY')}`
          }
          start_new = moment(end_new + ' ' + '00:00:00').utc().add('days', 2).utc().format('YYYY-MM-DD')
          end_new = moment(start_new + ' ' + '00:00:00').utc().add('days', 1 + diffDays).utc().format('YYYY-MM-DD')

          this.dateOption.push(payload)
        }
        this.paste_to_end = moment(val).utc().add('days', diffDays + 1).utc().format('YYYY-MM-DD')
      } else {
        this.showErrorMessage('Please select first copy start, end times')
      }
    },
    async makeDuplicateCopyFromDropdown () {

      let toDay = moment()

      const weekStart = toDay.clone().startOf('isoWeek')
      const weekEnd = toDay.clone().endOf('isoWeek')

      for (let i = 7; i >= 0; i--) {
        this.copyFromOptions.push({
          text: weekStart.clone().subtract(i, 'weeks').format('ddd DD MMMM YY') + ' - ' +
              weekEnd.clone().subtract(i, 'weeks').format('ddd DD MMMM YY'),
          value: moment(weekStart.clone().subtract(i, 'weeks').format('MM DD YY')).week(),
          from: weekStart.clone().add(i, 'weeks').format('YYYY-MM-DD'),
          to: weekEnd.clone().add(i, 'weeks').format('YYYY-MM-DD')
        })
      }
      this.copyFromOptions.reverse()

    },
    async makeDuplicatePastToDropdown () {

      let toDay = moment()

      const weekStart = toDay.clone().startOf('isoWeek')
      const weekEnd = toDay.clone().endOf('isoWeek')

      for (let i = 1; i <= 8; i++) {
        this.copyToOptions.push({
          text: weekStart.clone().add(i, 'weeks').format('ddd DD MMMM YY') + ' - ' +
              weekEnd.clone().add(i, 'weeks').format('ddd DD MMMM YY'),
          value: moment(weekStart.clone().add(i, 'weeks').format('MM DD YY')).week(),
          paste_start:weekStart.clone().add(i, 'weeks').format('YYYY-MM-DD'),
          paste_end:weekEnd.clone().add(i, 'weeks').format('YYYY-MM-DD'),
        })
      }
    },
    async getSelectedShiftCount (value) {
      // const start_date = value.from
      // const end_date = value.to
      // this.query.start = start_date
      // this.query.end = end_date
      // this.query.value = `${start_date}_${end_date}`
      const payload = {
        copy_start:this.selectedCopyFrom.from,
        copy_end:this.selectedCopyFrom.to,
      }
      this.selectedShiftCount = (await shift.getShiftCount(payload )).data.count
    },
    resetForm () {
      this.selectedCopyFrom = null
      this.selectedPastTo = null
      this.shift_with_allocated_staff = false
      this.$refs.duplicateShift.reset()
    },
    async duplicateFormSubmit () {
      if (await this.$refs.duplicateShift.validate()) {
        this.loading = true
        try {
          let past_to = this.selectedPastTo.map((x) => ({
            past_start:x.paste_start,
            past_end:x.paste_end
          }))
          await shift.duplicateMultipleDays({
            copy_start:this.selectedCopyFrom.from,
            copy_end:this.selectedCopyFrom.to,
            past_to: past_to,
            shift_with_allocated_staff: !this.shift_with_allocated_staff
          })
          this.showSuccessMessage('Duplicated successfully')
          this.loading = false
          this.isDuplicateShiftSidebarActive = false
          this.resetForm()
        } catch (e) {
          this.loading = false
          this.convertAndNotifyError(e)
          try {
            const payload = {
              start: moment(this.copy_to_start).utc().format('YYYY-MM-DD HH:mm:ss'),
              end: moment(this.copy_to_end).utc().format('YYYY-MM-DD HH:mm:ss'),
              paste_days: this.selectedRange,
              shift_with_allocated_staff: !this.shift_with_allocated_staff
            }
          } catch (error) {
          }
        }
      }
    }
  },

  mounted () {
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>



