<template>

  <b-sidebar
      id="add-new-user-create-sidebar"
      :visible="isAddNewUserCreateShiftSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-add-new-user-create-shift-sidebar-active', val)"

  >
    <template #default="{ hide }">
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Create Shift for {{selectedHealthcare_professional.text}} on {{ momentFormat(selectedDate, 'dddd, DD MMMM YYYY') }}
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />
        </div>
        <div class="p-2">
          <validation-observer ref="createShiftForm">
            <b-form @submit.prevent ref="add_shift">
              <b-alert
                  v-if="isHaveLeave"
                  show
                  variant="danger"
              >
                <div class="alert-body">
                  <feather-icon
                      class="mr-25"
                      icon="AlertCircleIcon"
                  />
                  <span class="ml-25">{{ leaveErrorMessage }}</span>
                </div>
              </b-alert>
              <b-row>

                <b-col cols="12" md="6">

                    <div class="w-100" style="background-color: #EFF7FC; padding: 10px" v-if="selectedRole.text">
                      <h6>Job Role</h6>
                      <span style="font-weight: bold">{{selectedRole.text}}</span>
                    </div>

                </b-col>
                <b-col cols="12" md="6">
                  <div class="w-100" style="background-color: #EFF7FC; padding: 10px" v-if="selectedHealthcare_professional.text">
                    <h6>Healthcare Professional</h6>
                    <span style="font-weight: bold">{{selectedHealthcare_professional.text}}</span>
                  </div>

                </b-col>
              </b-row>
                <b-row class="mt-1">
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label >Profession</label>
                      <v-select
                          v-model="selectedRole"
                          class="hide-selection"
                          label="text"
                          placeholder="Select Profession"
                          :options="projectResourceDataSource"
                          :disabled="true"
                      />
                      <!--                      <v-select-->
                      <!--                          v-model="selectedStaff"-->
                      <!--                          label="text"-->
                      <!--                          placeholder=" Select Healthcare Professional"-->
                      <!--                          :options="usersOption.filter(x => x.job_role_id === selectedJobRole.id)"-->
                      <!--                          :disabled="true"-->
                      <!--                      />-->
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                  <b-form-group>
                    <label>Role (Optional)</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Role"
                      rules=""
                    >
                      <v-select
                        v-model="selecetdRole"
                        :options="RoleLists"
                        label="text"
                        placeholder="Select Role"
                        :reduce="(RoleLists) => RoleLists.value"
                        multiple

                      >
                        <span slot="no-options">
                          <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              size="sm"
                              variant="primary"
                              @click="$router.push(`/settings`)"
                          >Add</b-button>
                          </span>
                      </v-select>
                      <small v-if="errors[0]" class="text-danger"
                        >The Professions field is required</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>

                </b-row>
              <h5 class="mt-3" v-if="ShiftTemplates.length !== 0">Shift templates (select applicable shift)</h5>

              <div class="container-fluid" >

                <div class="scrolling-wrapper row flex-row flex-nowrap pt-2">
                  <div class="col-5" v-for="template in ShiftTemplates" :index="template.id" :key="template.id" @click="shiftTemplateSet(template)">
                    <div class="card card-block card-1">
                      <b-row>
                        <b-col cols="6">
                          <span style="padding-left: 7px; color: white; font-size: x-small">
                            {{template.start_time}} - {{template.end_time}}
                          </span>
                        </b-col>
                        <b-col cols="6">
                          <div class="" style="background-color: white; text-overflow:ellipsis; margin-right: 7px">
                            <span style=" padding:3px ;color: #353434;    font-size: x-small">{{template.service.name.substring(0,12)}}</span>

                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <b-row>
<!--                  <b-col cols="12" md="4">-->
<!--                    <label>Date</label>-->

<!--                    <validation-provider-->
<!--                        #default="{ errors }"-->
<!--                        name="Date"-->
<!--                        rules="required"-->
<!--                    >-->
<!--                    <b-form-group>-->
<!--                      <flat-pickr-->
<!--                          v-model="selectedDate"-->
<!--                          :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d'}"-->
<!--                          class="form-control"-->
<!--                          placeholder="Select a date"-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                      <small class="text-danger">{{ errors[0] }}</small>-->
<!--                    </validation-provider>-->
<!--                  </b-col>-->
                  <b-col cols="12" md="4">
                    <b-form-group>
                    <label>Start Time</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Start Time"
                          rules="required"
                      >
                      <b-form-group>
                         <b-input-group class="input-group-merge">
                           <b-input-group-prepend is-text>
                              <feather-icon icon="ClockIcon" />
                            </b-input-group-prepend>
                        <flat-pickr
                            v-model="selectedStartTime"
                            :config="{ enableTime: true, dateFormat: 'H:i:ss', noCalendar: true , }"
                            class="form-control"
                            placeholder="Start Time"
                        />
                         </b-input-group>
                      </b-form-group>
                        <small  class="text-danger" v-if="errors[0]">The Start Time field is required</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                  <b-form-group>
                    <label>End Time</label>
                      <validation-provider
                          #default="{ errors }"
                          name="End Time"
                          rules="required"


                      >
                       <b-form-group>
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="ClockIcon" />
                            </b-input-group-prepend>
                        <flat-pickr
                            v-model="selectedEndTime"
                            :config="{ enableTime: true,  noCalendar: true, dateFormat: 'H:i:ss',}"
                            class="form-control"
                            placeholder="End Time"
                        />
                          </b-input-group>
                       </b-form-group>
                        <small   class="text-danger" v-if="errors[0]">The End Time field is required</small>
                      </validation-provider>
                    </b-form-group>

                  </b-col>
                  <b-col ols="12" md="4">
                    <b-form-group
                        label="Break Minutes (Optional) "
                        label-for="login-email"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Break Minutes"
                          rules="numeric"
                      >
                        <b-form-input
                            id="login-email"
                            v-model="form.break_minutes"
                            name="login-email"

                            type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <div>
                <b-row>
                  <b-col md="12">
                    <label>Shift Delivery (Optional)</label>
                    <v-select
                        v-model="selectedShiftType"
                        :options="shiftTypes"
                        label="name"
                        placeholder="Select Shift Type"
                    />
                  </b-col>
                </b-row>
              </div>
              <div class="mt-1">
                <h6 v-if="visible_locums == false" class="mb-2">Hourly rate (Optional)</h6>
                <b-row v-if="visible_locums == false"  class="mb-1">
                  <b-col cols="3">
                    <b-form-checkbox
                        v-model="form.salary_staff_rate_type"
                        value="standard"

                    >
                      Standard
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="3">
                    <b-form-checkbox
                        v-model="form.salary_staff_rate_type"
                        value="enhanced"

                    >
                      Enhanced
                    </b-form-checkbox>
                  </b-col>
                </b-row>

                <validation-provider v-if="visible_locums == false && (form.salary_staff_rate_type === 'standard' || form.salary_staff_rate_type === 'enhanced')"
                                     #default="{ errors }"
                                     class=""
                                     rules="required|numeric"
                                     name="Salaried hourly rate"
                >
                  <b-input-group
                      size="md"
                      prepend="£"
                  >
                    <b-form-input
                        id="stand"
                        v-model="form.salary_staff_rate"
                        class="float-right"
                        placeholder="Enter hourly rate"
                        type="number"
                    />
                  </b-input-group>
                  <small v-if="form.salary_staff_rate_type === 'standard' && !standard_rate_p_h">Please add standard rate in setting or enter manually</small>
                  <small v-if="form.salary_staff_rate_type === 'enhanced' && !enhanced_rate_p_h">Please add enhanced rate in setting or enter manually</small>
                  <br/>
                  <!--                  <b-input-group-->
                  <!--                      size="sm"-->
                  <!--                      append=".00"-->
                  <!--                      prepend="$"-->
                  <!--                  >-->
                  <!--                  <b-form-input-->
                  <!--                      id="stand"-->
                  <!--                      v-model="form.salary_staff_rate"-->
                  <!--                      class="float-right mb-1   "-->
                  <!--                      name="login-email"-->
                  <!--                      placeholder="Enter hourly rate"-->

                  <!--                      type="number"-->
                  <!--                  />-->
                  <!--                  </b-input-group>-->
                  <small class="text-danger ">{{ errors[0] }}</small>
                </validation-provider>

                <div v-if="visible_locums === true ">
                  <h6 class="mb-1">Hourly rate locums</h6>

                  <validation-provider
                      #default="{ errors }"
                      name="Locum hourly rate"
                      rules="required"
                  >
                    <b-input-group
                        size="md"
                        prepend="£"
                    >
                      <b-form-input
                          :disabled="!isVacant"
                          id="rate"
                          v-model="form.locum_rate"
                          class="float-right"
                          name="login-email"
                          placeholder="Enter hourly rate"
                          type="number"
                      />

                    </b-input-group>
                    <small v-if="!locum_rate_p_h">Please add locum rate in setting or enter manually</small>
                    <br/>
                    <!--                    <b-form-input-->
                    <!--                        id="rate"-->
                    <!--                        v-model="form.locum_rate"-->
                    <!--                        class="float-right "-->
                    <!--                        name="login-email"-->
                    <!--                        placeholder="Enter hourly rate"-->

                    <!--                        type="number"-->
                    <!--                    />-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>


              </div>

              <div>
                <h5 class="mb-2 cursor-pointer " @click="hideNoteAndShiftTypeFunction()">
                  <span v-if="!hideNoteAndShiftType">+</span><span v-if="hideNoteAndShiftType">-</span>
                  More shift options</h5>
                <div class="mt-1" v-if="hideNoteAndShiftType">
                  <div v-if="clinicalAndNonClinicalServices.length>0" class="mt-1">
                    <b-row class="mt-2" >
                      <b-col md="12">
                        <h5 class="mb-2 cursor-pointer ">
                          Activity (Optional)</h5>
                      </b-col>

                      <b-col md="6">
                        <b-form-checkbox
                            v-model="selectedServiceType"
                            class="float-left"
                            value="clinical"
                        >
                          Clinical
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="selectedServiceType"
                            class="float-right"
                            value="non-clinical"
                        >
                          Non-clinical
                        </b-form-checkbox>


                      </b-col>
                      <b-col cols="12" md="12">
                        <b-form-group>
                          <label></label>
                          <!--                      rules="required"-->
                          <validation-provider
                              #default="{ errors }"
                              name="Service"
                              :rules="selectedServiceType ? 'required' : ''"
                          >

                            <v-select
                                v-if="selectedServiceType ==='clinical'"
                                v-model="selectedService"
                                :options="services.filter(x => x.type==='clinical')"
                                label="name"
                                placeholder="Select Service"
                            >
                        <span slot="no-options">
                                Please add services first
                        </span>
                            </v-select>
                            <v-select
                                v-if="selectedServiceType ==='non-clinical'"
                                v-model="selectedService"
                                :options="services.filter(x => x.type ==='non-clinical')"
                                label="name"
                                placeholder="Select Service"
                            >
                          <span slot="no-options">
                                Please add services first
                        </span>
                            </v-select>
                            <small v-if="selectedServiceType" class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                      </b-col>
                    </b-row>


                  </div>

                  <div class="mt-1">
                    <label>Notes (Optional)</label>
                    <b-form-textarea
                        id="textarea-default"
                        v-model="form.note"
                        placeholder="Note"
                        rows="3"
                    />
                  </div>
                  <b-form-checkbox class="mt-2"
                                   v-model="form.is_covid_shift"
                                   :value="true"
                  >
                    COVID-19 Shift
                  </b-form-checkbox>


                </div>
              </div>





              <div class="mt-2">

                <b-alert
                    show
                    variant="primary"
                >
                  <div class="alert-body">
                    <b-row>
                      <b-col class="text-bold text-black-50" cols="4">
                        <h5>Total Hours:
                          <span v-if="selectedEndTime&&selectedStartTime">
                            {{ totalHours }}

                            <!--                            {{ selectedEndTime.substring(0, 2) - selectedStartTime.substring(0, 2) }}-->
                          </span>
                          <span v-else>0</span>
                        </h5>
                      </b-col>
                      <b-col class="text-bold text-black-50" cols="8"><h5>Total Shift Cost:

                        <span>
                          <span>£ {{ salariedStaffCost }}</span><span
                            v-if="visible_locums === true ">/ £{{ locumCost }}</span>


                          <!--                          {{totalShiftCost(selectedStartTime,selectedEndTime, 10)}}-->

                          </span>
                      </h5></b-col>


                    </b-row>
                  </div>
                </b-alert>


              </div>


              <div class="flex mt-1">
                <b-button
                    v-if="isHaveLeave"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    disabled
                    type="submit"
                    variant="primary"
                    @click="createShift()"
                >
                  Save
                </b-button>
                <b-button
                    v-else
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    @click="createShift()"
                >
                  Add
                </b-button>


                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="white"
                    @click=hide
                    class="ml-1"
                    style="background: #FFFFFF; box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.2); border-radius: 4px;"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>


        <!--        {{projectResourceDataSource}}-->
        <!--        {{selectedRole}}-->
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  VBModal,
  VBToggle,
  VBTooltip,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'

import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import shift from '@/apis/modules/shift'
import MomentMixin from '@/mixins/MomentMixin'
import moment from 'moment'
import SettingsAPI from '@/apis/modules/settings'
import settings from '@/apis/modules/settings'
export default {
  name: 'sideBar',
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BFormGroup,
    BButton,
    BSidebar,
    BFormCheckbox,
    BFormTextarea,
    flatPickr,
    BOverlay,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BAlert,
    BFormSelect,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BFormInvalidFeedback,
    BInputGroupPrepend,
    BInputGroupAppend,
    VBTooltip

  },
  mixins: [MomentMixin],
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal
  },
  data () {
    return {
      selecetdRole:'',
      RoleLists:[],
      is_cell_clicked: false,
      totalHours: 0,
      salariedStaffCost: 0,
      locumCost: 0,
      rateCost: 0,
      roleHourlyRates: {},
      isHaveLeave: false,
      leaveErrorMessage: '',
      loading: false,
      dasibleProfession: false,
      isVacant: false,
      hideNoteAndShiftType: false,
      breakTimeHide: false,
      selectedHealthcare_professional: undefined,
      selectedRole: undefined,
      roleList:[],
      selectedServiceType: 'clinical',
      selectedDate: '',
      selectedStartTime: '',
      selectedEndTime: '',
      data: '',
      selected: '',
      timePicker: '',
      services: [],
      selectedService: '',
      selectedShiftType: '',
      shiftTypes: [],
      visible_locums: false,
      form: {
        is_visible_locums: '',
        start: '',
        end: '',
        note: '',
        break_minutes: '',
        service_id: '',
        assignee_id: '',
        is_covid_shift: false,
        shift_type_id: '',
        salary_staff_rate_type: 'standard',
        salary_staff_rate: 0,
        locum_rate: 0,
        role_id : undefined

      },
      employeeDataSource: [],
      projectResourceDataSource: [],
      enhanced_rate_p_h:'',
      standard_rate_p_h:'',
      locum_rate_p_h:'',
      ShiftTemplates:[],
      clinicalAndNonClinicalServices:[]
    }
  },
  model: {
    prop: 'isAddNewUserCreateShiftSidebarActive',
    event: 'update:is-add-new-user-create-shift-sidebar-active'
  },
  props: {
    isAddNewUserCreateShiftSidebarActive: {
      type: Boolean,
      required: true
    },
    cellData: {
      type: Object,
      required: true
    },
    leaveData: {
      type: Array,
      required: true
    },

  },
  watch: {
    async isAddNewUserCreateShiftSidebarActive (val) {

      if (!val){
        await this.dataClear()
        // this.selectedRole = undefined
        // this.selectedHealthcare_professional = undefined
        // this.selectedDate =  await this.momentFormat(new Date(), 'YYYY-MM-DD')

        return
      }else {
        await this.getUserData()
        await this.getServices()
        await this.getShiftTypes()

        await this.getShiftTemplates()
        await this.getShiftTypes()
        this.isHaveLeave = false
        this.leaveErrorMessage = ''
        this.selectedStartTime = '08:00:00'
      }

     /* if (!this.is_cell_clicked){
         this.selectedRole = undefined
         this.selectedHealthcare_professional = undefined
        this.$nextTick(() => {
          this.$refs.add_shift.reset()
        })
      }*/


    },
    'form.salary_staff_rate_type'(val){
      if(val == 'enhanced'){
        this.form.salary_staff_rate = this.enhanced_rate_p_h?this.enhanced_rate_p_h:0
      }else{
        this.form.salary_staff_rate = this.standard_rate_p_h?this.standard_rate_p_h:0
      }

    },
    async cellData () {
      this.data = await this.cellData
      if (this.data) {
        this.selectedDate = await this.momentFormat(this.data.data.StartTime, 'YYYY-MM-DD')
      }

    },
    selectedServiceType () {
      // this.getServices()


    },
    isVacant () {
      if (this.isVacant) {
        this.dasibleProfession = true
        this.selectedHealthcare_professional = undefined
      } else {
        this.dasibleProfession = false
      }
    },
    selectedDate () {
      this.isUserHaveLeave()
    },
    selectedStartTime () {
      this.isUserHaveLeave()
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, this.form.salary_staff_rate)

    },
    selectedEndTime () {
      this.isUserHaveLeave()
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, this.form.salary_staff_rate)


    },

    'form.break_minutes'() {
      this.isUserHaveLeave()
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime,this.form.break_minutes)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, this.form.salary_staff_rate)

    },

    selectedHealthcare_professional () {
      // this.isVacant = false
      this.isUserHaveLeave()
    },
    selectedRole (val) {

      this.isUserHaveLeave()
      this.getJobRoleList(val.id)
      this.getRole(val)
    },
    'form.salary_staff_rate' (newValue) {
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime,this.form.break_minutes)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, newValue)
    },
    'form.locum_rate' (newValue) {
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime,this.form.break_minutes)
      this.locumCost = this.totalShiftCost(this.totalHours, newValue)
    }


  },
  methods: {
      async getRole(val){
      try{
        let AllRoles=[]
       const Response= await settings.getRolesWithoutPagination()
        AllRoles = Response.data.data.map((x) => ({
          text: x.name,
          value: x.id,
          profession : x.professions,
        }))
      let selectedRoleIDS = []
      AllRoles.map((x)=>{
        x.profession.map((y)=>{
          if(val.id === y)
              selectedRoleIDS.push(x.value)
         })
      this.RoleLists = selectedRoleIDS.map((x)=>{
        return AllRoles.find(e=>e.value === x)
        })
      })
      }catch(e){

      }
    },
    async cellClicked () {
      this.is_cell_clicked = true
      setTimeout(() => {
        this.is_cell_clicked = false
      },1000)
    },
    async isUserHaveLeave () {
      const leaves = this.leaveData.filter(leave => this.momentFormat(leave.StartTime, 'YYYY-MM-DD') === this.selectedDate
          && leave.group_id === this.selectedRole.id && leave.user_id === this.selectedHealthcare_professional.id)
      if (leaves.length !== 0) {
        if (leaves.filter(leave => leave.is_day === 1).length !== 0) {
          // this.showErrorMessage(`${this.selectedHealthcare_professional.text} have full day leave on ${this.selectedDate}` )
          this.isHaveLeave = true
          this.leaveErrorMessage = `${this.selectedHealthcare_professional.text} have full day leave on ${this.selectedDate}`
        }
        if (leaves.filter(leave => leave.is_day === 0).length !== 0) {
          const leaveStartTime = moment(leaves[0].StartTime, 'hh:mm:ss')
          const leaveEndTime = moment(leaves[0].EndTime, 'hh:mm:ss')
          const beforeTime = moment(new Date((this.selectedDate + ` ` + this.selectedStartTime)), 'hh:mm:ss')
          const afterTime = moment(new Date((this.selectedDate + ` ` + this.selectedEndTime)), 'hh:mm:ss')
          const leaveType = leaves[0].state

          if (leaveStartTime.isBetween(beforeTime, afterTime) || leaveEndTime.isBetween(beforeTime, afterTime)
              || beforeTime.isBetween(leaveStartTime, leaveEndTime) || afterTime.isBetween(leaveStartTime, leaveEndTime)
          ) {
            // this.showErrorMessage(`${this.selectedHealthcare_professional.text}
            //  have  leave on ${this.momentFormat(leaveStartTime,'HH:mm')} - ${this.momentFormat(leaveEndTime, 'HH:mm')}` )

            this.isHaveLeave = true
            if (leaveType ==='leave'){this.leaveErrorMessage = `${this.selectedHealthcare_professional.text}
             have  leave on ${this.momentFormat(leaveStartTime, 'HH:mm')} - ${this.momentFormat(leaveEndTime, 'HH:mm')}`}else {
            this.leaveErrorMessage = `${this.selectedHealthcare_professional.text}
             have  Un Available on ${this.momentFormat(leaveStartTime, 'HH:mm')} - ${this.momentFormat(leaveEndTime, 'HH:mm')}`}

          } else {
            this.isHaveLeave = false
          }
        }


      } else {
        this.isHaveLeave = false
      }
    },
    getCalculatedCost () {
      return (this.selectedEndTime.substring(0, 2) - this.selectedStartTime.substring(0, 2)) * this.form.rate
    },
    hideNoteAndShiftTypeFunction () {
      this.hideNoteAndShiftType = !this.hideNoteAndShiftType

    },
    async getServices (filter) {
      try {
        this.loading = true
        const serviceResponse = await shift.getServicesInPractice(filter)

        this.selectedService= ''
        this.services = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id,
          type:x.type

        }))
        this.clinicalAndNonClinicalServices=this.clinicalAndNonClinicalServices.concat( this.services)

        this.loading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },
    async getShiftTypes () {
      try {
        this.loading = true
        const serviceResponse = await shift.getShiftTypesInPractice()

        this.shiftTypes = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id
        }))
        this.loading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },
    async getUserData () {
      try {

        this.loading = true

        const response = await shift.userList()
        // const jobRoles = response.data.data[0].job_roles[0]
        const users = response.data.data.filter(user => user.job_roles.length !== 0)

        let groupArray = users.reduce(function (filtered, option) {
          option.job_roles.forEach((m) => {
            var obj = {
              text: m.name,
              id: m.id,
              color: '#00C49A',
              isGroup: true
            }
            filtered.push(obj)
          })
          filtered = [...new Map(filtered.map(item => [item.id, item])).values()]

          return filtered
        }, [])

        let userArray = users.map((x) => ({
          text: `${x.first_name} ${x.last_name}`,
          id: x.id,
          groupId: x.job_roles[0].id,
          image: '1'
        }))
        if (this.data.data) {
          this.selectedRole = Object(groupArray.filter(m => m.id === this.data.data.group_id))[0]
          this.selectedHealthcare_professional = Object(userArray.filter(m => m.id === this.data.data.user_id))[0]
        }
        this.projectResourceDataSource = groupArray
        this.employeeDataSource = userArray
        this.loading = false
      } catch (error) {

        this.convertAndNotifyError(error)
        this.loading = false

      }

    },
    async getJobRoleList(id){
      try {
        this.loading = true
        const Response = await SettingsAPI.jobRoleAssignedList()
        this.roleList = Response.data.data.map((x) => ({
              id: x.id,
              job_role_id: x.pivot.job_role_id,
              role: x.name,
              standard_rate_p_h: x.pivot.standard_rate_p_h,
              enhanced_rate_p_h: x.pivot.enhanced_rate_p_h,
              locum_rate_p_h: x.pivot.locum_rate_p_h
            })
        )

        const role = []
        this.roleList.forEach(function(x){
          if(x.id == id){
            role.push(x)
          }
        })

        if(role.length == 0){
          this.form.salary_staff_rate = 0
          this.form.locum_rate = 0
          this.enhanced_rate_p_h=''
          this.standard_rate_p_h=''
          this.locum_rate_p_h=''
        }

        this.loading = false
        this.locum_rate_p_h = role[0].locum_rate_p_h
        this.standard_rate_p_h = role[0].standard_rate_p_h
        this.enhanced_rate_p_h = role[0].enhanced_rate_p_h
        this.form.locum_rate = role[0].locum_rate_p_h? role[0].locum_rate_p_h : 0
        this.form.salary_staff_rate = role[0].standard_rate_p_h ? role[0].standard_rate_p_h: 0

      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },
    async dataClear () {
     /* this.$nextTick(() => {
        this.$refs.add_shift.reset()
      })*/
      this.data = null
      this.isVacant = false
      this.selectedService = []
      this.form.rate = ''
      this.selectedServiceType = ''
      this.selectedShiftType = ''
      this.hideNoteAndShiftType = false
      this.totalHours = 0
      this.selectedStartTime = undefined
      this.selectedEndTime = undefined
      this.selectedDate = ''
      this.form.note = ''
      this.form.salary_staff_rate = ''
      this.form.salary_staff_rate_type = 'standard'
      this.form.locum_rate = ''
      this.enhanced_rate_p_h='',
      this.standard_rate_p_h='',
      this.locum_rate_p_h=''
      this.$refs.createShiftForm.reset();
    },

    async createShift () {
      if (await this.$refs.createShiftForm.validate()) {

        try {
          // let roleIds = this.selecetdRole.map((x)=>{
          //   return x.value
          // })
          this.loading = true
            this.form.is_visible_locums = this.visible_locums
            this.form.job_role_id = this.selectedRole.id
            this.form.service_id = this.selectedService.id
            this.form.role_id = this.selecetdRole
            this.form.shift_type_id = this.selectedShiftType.id
            this.form.start = moment(this.selectedDate + ` ` + this.selectedStartTime).utc().format('YYYY-MM-DD HH:mm:ss')
            this.form.end = moment(this.selectedDate + ` ` + this.selectedEndTime).utc().format('YYYY-MM-DD HH:mm:ss')
            this.form.assignee_id = this.isVacant?'':this.selectedHealthcare_professional.id

         const response = await shift.createShift(this.form)

          this.loading = false
          if(response.data.data.error) {
            this.showErrorMessage('There is an existing shift in this time slot')
            this.loading = false
          }else {
            this.showSuccessMessage('Shift Added Successfully')
            this.$emit('createShiftUser')
            this.loading = false
          }

        } catch (error) {
          this.convertAndNotifyError(error)
          this.loading = false
        }
      }
    },

    async shiftTemplateSet( template){

      this.form.break_minutes = template.break_minutes
      this.selectedStartTime = template.start_time
      this.selectedEndTime = template.end_time
      this.serviceTypeHide = true
      this.selectedServiceType = template.service.type
      this.selectedService = template.service
      this.form.note = template.note
      // if (template.service.type ==='clinical'){
      //
      // }else {
      //
      // }
      // this.selectedService = template.service

      try {
      }catch (error){
        this.convertAndNotifyError(error)
      }
    },

    async getShiftTemplates(){
      try {
        const response = await shift.getShiftTemplateWithoutPagination()
        this.ShiftTemplates = response.data.data.map((x)=>({
          id:x.id,
          start_time:x.start_time,
          end_time:x.end_time,
          break_minutes:x.break_minutes,
          note:x.note,
          service:{
            name: x.service.name,
            id: x.service.id,
            type:x.service.type
          }
        }))

      }catch (error) {
        this.convertAndNotifyError(error)

      }

    },
  },
  mounted () {

  }


}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

//.vs__selected {
//  display: flex;
//  align-items: flex-start !important;
//}
//
//[dir] .v-select.vs--single .vs__selected {
//    /* margin-top: 5px; */
//    overflow: hidden;
//    text-overflow: ellipsis;
//    overflow-wrap: break-word;
//    padding: 1px;
//}
//
.scrolling-wrapper{
  overflow-x: auto;
}
.hide-selection{
  .vs__actions{
    display: none;
  }
}


.card-block{
  height: 40px;
  padding-top: 10px;
  background-color: #fff;
  border: none;
  background-position: center;
  background-size: cover;
  transition: all 0.2s ease-in-out !important;
  border-radius: 5px;
  &:hover{
    transform: translateY(-5px);
    box-shadow: none;
    opacity: 0.9;
    background-image: linear-gradient(43deg, #00c49a 0%, #00c49a 46%, #00c49a 100%);


  }
  &:focus{
    transform: translateY(-5px);
    box-shadow: none;
    opacity: 0.9;
    background-image: linear-gradient(43deg, #00c49a 0%, #00c49a 46%, #00c49a 100%);

  }
}

.card-1{
  background-color: #4158D0;
  background-image: linear-gradient( #B2B2B2 100%, #B2B2B2 100%, #B2B2B2 100%);
}


</style>
