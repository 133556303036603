var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar",
      visible: _vm.isEditShiftSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-edit-shift-sidebar-active", val)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
                },
                [
                  _c("h5", { staticClass: "mb-0" }, [_vm._v(" Edit Shift ")]),
                  _c("feather-icon", {
                    staticClass: "ml-1 cursor-pointer",
                    attrs: { icon: "XIcon", size: "16" },
                    on: { click: hide }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "p-1" },
                [
                  _c(
                    "b-tabs",
                    [
                      _c(
                        "b-tab",
                        { attrs: { active: "", title: "Shift Details" } },
                        [
                          _c(
                            "validation-observer",
                            { ref: "editShiftForm" },
                            [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "p-1" },
                                    [
                                      _vm.is_vacant_shift === "true"
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: { cols: "12", md: "6" }
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _c("label", [
                                                        _vm._v("Professions")
                                                      ]),
                                                      _c("v-select", {
                                                        attrs: {
                                                          options: _vm.roleList,
                                                          reduce: function(
                                                            roleList
                                                          ) {
                                                            return roleList.id
                                                          },
                                                          label: "text",
                                                          multiple: "",
                                                          placeholder:
                                                            "Select Role"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedVacantRole,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.selectedVacantRole = $$v
                                                          },
                                                          expression:
                                                            "selectedVacantRole"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: { cols: "12", md: "6" }
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          "Role (Optional)"
                                                        )
                                                      ]),
                                                      _c("v-select", {
                                                        attrs: {
                                                          options:
                                                            _vm.RoleLists,
                                                          reduce: function(
                                                            RoleLists
                                                          ) {
                                                            return RoleLists.value
                                                          },
                                                          label: "text",
                                                          multiple: "",
                                                          placeholder:
                                                            "Select Professions"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedRoles,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.selectedRoles = $$v
                                                          },
                                                          expression:
                                                            "selectedRoles"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: { cols: "12", md: "6" }
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _c("label", [
                                                        _vm._v("Professions")
                                                      ]),
                                                      _c("v-select", {
                                                        attrs: {
                                                          options:
                                                            _vm.projectResourceDataSource,
                                                          label: "text",
                                                          placeholder:
                                                            "Select Profession"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedRoleForEdit,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.selectedRoleForEdit = $$v
                                                          },
                                                          expression:
                                                            "selectedRoleForEdit"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: { cols: "12", md: "6" }
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          "Role (Optional)"
                                                        )
                                                      ]),
                                                      _c("v-select", {
                                                        attrs: {
                                                          options:
                                                            _vm.RoleLists,
                                                          reduce: function(
                                                            RoleLists
                                                          ) {
                                                            return RoleLists.value
                                                          },
                                                          label: "text",
                                                          multiple: "",
                                                          placeholder:
                                                            "Select Role"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedRoles,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.selectedRoles = $$v
                                                          },
                                                          expression:
                                                            "selectedRoles"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                      _c(
                                        "div",
                                        {},
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12"
                                                  }
                                                },
                                                [
                                                  _c("label", [_vm._v("Date")]),
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Date",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-group",
                                                                [
                                                                  _c(
                                                                    "b-form-datepicker",
                                                                    {
                                                                      staticClass:
                                                                        "form-control",
                                                                      attrs: {
                                                                        id:
                                                                          "date",
                                                                        "date-format-options": {
                                                                          month:
                                                                            "numeric",
                                                                          day:
                                                                            "numeric",
                                                                          year:
                                                                            "numeric"
                                                                        },
                                                                        min:
                                                                          _vm.min,
                                                                        locale:
                                                                          "en-UK",
                                                                        placeholder:
                                                                          "Selects",
                                                                        "start-weekday":
                                                                          "1",
                                                                        trim: ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.selectedDateForEdit,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.selectedDateForEdit = $$v
                                                                        },
                                                                        expression:
                                                                          "selectedDateForEdit"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: { cols: "12", md: "4" }
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _c("label", [
                                                        _vm._v("Start Time")
                                                      ]),
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name: "Start Time",
                                                            rules: "required"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "b-form-group",
                                                                      [
                                                                        _c(
                                                                          "b-input-group",
                                                                          {
                                                                            staticClass:
                                                                              "input-group-merge"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-input-group-prepend",
                                                                              {
                                                                                attrs: {
                                                                                  "is-text":
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "feather-icon",
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "ClockIcon"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "flat-pickr",
                                                                              {
                                                                                staticClass:
                                                                                  "form-control",
                                                                                attrs: {
                                                                                  config: {
                                                                                    enableTime: true,
                                                                                    noCalendar: true,
                                                                                    dateFormat:
                                                                                      "H:i:ss"
                                                                                  }
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm.selectedStartTime,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.selectedStartTime = $$v
                                                                                  },
                                                                                  expression:
                                                                                    "selectedStartTime"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: { cols: "12", md: "4" }
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _c("label", [
                                                        _vm._v("End Time")
                                                      ]),
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name: "End Time",
                                                            rules: "required"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "b-form-group",
                                                                      [
                                                                        _c(
                                                                          "b-input-group",
                                                                          {
                                                                            staticClass:
                                                                              "input-group-merge"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-input-group-prepend",
                                                                              {
                                                                                attrs: {
                                                                                  "is-text":
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "feather-icon",
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "ClockIcon"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "flat-pickr",
                                                                              {
                                                                                staticClass:
                                                                                  "form-control",
                                                                                attrs: {
                                                                                  config: {
                                                                                    enableTime: true,
                                                                                    noCalendar: true,
                                                                                    dateFormat:
                                                                                      "H:i:ss"
                                                                                  }
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm.selectedEndTime,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.selectedEndTime = $$v
                                                                                  },
                                                                                  expression:
                                                                                    "selectedEndTime"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: { cols: "12", md: "4" }
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: "Break Minutes",
                                                        "label-for":
                                                          "login-email"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name:
                                                              "Break Minutes",
                                                            rules: "numeric"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        attrs: {
                                                                          id:
                                                                            "login-email",
                                                                          name:
                                                                            "login-email",
                                                                          type:
                                                                            "number"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formEdit
                                                                              .break_minutes,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.formEdit,
                                                                              "break_minutes",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "formEdit.break_minutes"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _vm.selectedServiceForEdit &&
                                          _vm.selectedServiceTypeforEdit ===
                                            "clinical" &&
                                          _vm.servicesForEdit.length !== 0
                                            ? _c(
                                                "b-row",
                                                {},
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { md: "12" } },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              " Shift Delivery (Optional)"
                                                            )
                                                          ]),
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                name:
                                                                  "Shift Type",
                                                                rules:
                                                                  "required"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _c(
                                                                          "v-select",
                                                                          {
                                                                            attrs: {
                                                                              options:
                                                                                _vm.shiftTypesForEdit,
                                                                              label:
                                                                                "name",
                                                                              placeholder:
                                                                                "Select Shift Type"
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.selectedShiftTypeForEdit,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.selectedShiftTypeForEdit = $$v
                                                                              },
                                                                              expression:
                                                                                "selectedShiftTypeForEdit"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {},
                                        [
                                          _c("h6", { staticClass: "mb-2" }, [
                                            _vm._v(
                                              "Applicable Hourly Rate for salaried staff"
                                            )
                                          ]),
                                          _c(
                                            "b-row",
                                            { staticClass: "mb-1" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        value: "standard"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formEdit
                                                            .salary_staff_rate_type,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formEdit,
                                                            "salary_staff_rate_type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formEdit.salary_staff_rate_type"
                                                      }
                                                    },
                                                    [_vm._v(" Standard ")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        value: "enhanced"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formEdit
                                                            .salary_staff_rate_type,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formEdit,
                                                            "salary_staff_rate_type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formEdit.salary_staff_rate_type"
                                                      }
                                                    },
                                                    [_vm._v(" Enhanced ")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Hourly rate",
                                                      rules: "numeric"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  staticClass:
                                                                    "float-right mb-2",
                                                                  attrs: {
                                                                    id: "stand",
                                                                    name:
                                                                      "login-email",
                                                                    placeholder:
                                                                      "Enter hourly rate",
                                                                    type:
                                                                      "number"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .formEdit
                                                                        .salary_staff_rate,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.formEdit,
                                                                        "salary_staff_rate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "formEdit.salary_staff_rate"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.visible_locums === true
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "h6",
                                                    { staticClass: "mb-2" },
                                                    [
                                                      _vm._v(
                                                        "Applicable Hourly Rate locums"
                                                      )
                                                    ]
                                                  ),
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Hourly rate",
                                                      rules: "numeric"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  staticClass:
                                                                    "float-right mb-2",
                                                                  attrs: {
                                                                    id: "rate",
                                                                    name:
                                                                      "login-email",
                                                                    placeholder:
                                                                      "Enter hourly rate",
                                                                    type:
                                                                      "number"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .formEdit
                                                                        .locum_rate,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.formEdit,
                                                                        "locum_rate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "formEdit.locum_rate"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm.is_vacant_shift !== "true"
                                        ? _c(
                                            "div",
                                            { attrs: { hidden: "true" } },
                                            [
                                              _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              "Healthcare Professional"
                                                            )
                                                          ]),
                                                          _c("v-select", {
                                                            attrs: {
                                                              options: _vm.employeeDataSource.filter(
                                                                function(x) {
                                                                  return (
                                                                    x.groupId ===
                                                                    _vm
                                                                      .selectedRoleForEdit
                                                                      .id
                                                                  )
                                                                }
                                                              ),
                                                              label: "text",
                                                              placeholder:
                                                                "Select Healthcare Professional"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedHealthcare_professionalForEdit,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.selectedHealthcare_professionalForEdit = $$v
                                                              },
                                                              expression:
                                                                "selectedHealthcare_professionalForEdit"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {},
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { value: "true" },
                                              model: {
                                                value: _vm.is_vacant_shift,
                                                callback: function($$v) {
                                                  _vm.is_vacant_shift = $$v
                                                },
                                                expression: "is_vacant_shift"
                                              }
                                            },
                                            [_vm._v(" Vacant Shift ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-2" },
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-2" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "12" } },
                                                [
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass:
                                                        "mb-2 cursor-pointer ",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.serviceTypeHideForEditFunction()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.serviceTypeHideForEdit ===
                                                              true
                                                              ? "-"
                                                              : "+"
                                                          ) +
                                                          " More Shift (Options)"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { md: "12" } },
                                                [
                                                  _vm.serviceTypeHideForEdit
                                                    ? _c(
                                                        "h5",
                                                        { staticClass: "mb-2" },
                                                        [
                                                          _vm._v(
                                                            "Activity Option"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _vm.serviceTypeHideForEdit
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "6" } },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "float-left",
                                                          attrs: {
                                                            name:
                                                              "selectedServiceTyper",
                                                            value: "clinical"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedServiceTypeforEdit,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.selectedServiceTypeforEdit = $$v
                                                            },
                                                            expression:
                                                              "selectedServiceTypeforEdit"
                                                          }
                                                        },
                                                        [_vm._v(" Clinical ")]
                                                      ),
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "float-right",
                                                          attrs: {
                                                            name:
                                                              "selectedServiceType",
                                                            value:
                                                              "non-clinical"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedServiceTypeforEdit,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.selectedServiceTypeforEdit = $$v
                                                            },
                                                            expression:
                                                              "selectedServiceTypeforEdit"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Non-clinical "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm.serviceTypeHideForEdit
                                            ? _c(
                                                "b-row",
                                                {},
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mt-1",
                                                      attrs: { md: "12" }
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                name: "Service",
                                                                rules:
                                                                  "required"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _vm.selectedServiceTypeforEdit ==
                                                                        null
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "Please select service type"
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "v-select",
                                                                          {
                                                                            attrs: {
                                                                              options:
                                                                                _vm.servicesForEdit,
                                                                              label:
                                                                                "name",
                                                                              placeholder:
                                                                                "Select Service"
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.selectedServiceForEdit,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.selectedServiceForEdit = $$v
                                                                              },
                                                                              expression:
                                                                                "selectedServiceForEdit"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                attrs: {
                                                                                  slot:
                                                                                    "no-options"
                                                                                },
                                                                                slot:
                                                                                  "no-options"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " Please add services first "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-row",
                                            {},
                                            [
                                              _vm.serviceTypeHideForEdit
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "12" } },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          "Notes (optional)"
                                                        )
                                                      ]),
                                                      _c("b-form-textarea", {
                                                        attrs: {
                                                          id:
                                                            "textarea-default",
                                                          placeholder: "Note",
                                                          rows: "3"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formEdit.note,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formEdit,
                                                              "note",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formEdit.note"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm.serviceTypeHideForEdit
                                            ? _c(
                                                "b-row",
                                                {
                                                  staticClass: "mt-1",
                                                  attrs: { md: "12" }
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: { value: "" },
                                                          model: {
                                                            value:
                                                              _vm.selectedCoveid,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.selectedCoveid = $$v
                                                            },
                                                            expression:
                                                              "selectedCoveid"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " COVID-19 Shift "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-1" },
                                        [
                                          _c(
                                            "h5",
                                            { staticClass: "mb-2 text-bold" },
                                            [_vm._v("Shift is visible to")]
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: { cols: "12", md: "4" }
                                                },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: { value: false },
                                                      model: {
                                                        value:
                                                          _vm.visible_locums,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.visible_locums = $$v
                                                        },
                                                        expression:
                                                          "visible_locums"
                                                      }
                                                    },
                                                    [_vm._v(" Salaried Staff ")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: { cols: "12", md: "8" }
                                                },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: { value: true },
                                                      model: {
                                                        value:
                                                          _vm.visible_locums,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.visible_locums = $$v
                                                        },
                                                        expression:
                                                          "visible_locums"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Salaried Staff and Locums "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c(
                                            "b-alert",
                                            {
                                              attrs: {
                                                show: "",
                                                variant: "primary"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "alert-body" },
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass:
                                                            "text-bold text-black-50",
                                                          attrs: { cols: "4" }
                                                        },
                                                        [
                                                          _c("h5", [
                                                            _vm._v(
                                                              "Total Hours: "
                                                            ),
                                                            _vm.selectedEndTime &&
                                                            _vm.selectedStartTime
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.totalHours
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v("0")
                                                                ])
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass:
                                                            "text-bold text-black-50",
                                                          attrs: { cols: "8" }
                                                        },
                                                        [
                                                          _c("h5", [
                                                            _vm._v(
                                                              "Total Shift Cost: "
                                                            ),
                                                            _c("span", [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "£ " +
                                                                    _vm._s(
                                                                      _vm.salariedStaffCost
                                                                    )
                                                                )
                                                              ]),
                                                              _vm.visible_locums ===
                                                              "true"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "/ £" +
                                                                        _vm._s(
                                                                          _vm.locumCost
                                                                        )
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ])
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "flex mt-1" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(255, 255, 255, 0.15)",
                                                  expression:
                                                    "'rgba(255, 255, 255, 0.15)'",
                                                  modifiers: { "400": true }
                                                }
                                              ],
                                              attrs: {
                                                type: "submit",
                                                variant: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editShift()
                                                }
                                              }
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(255, 255, 255, 0.15)",
                                                  expression:
                                                    "'rgba(255, 255, 255, 0.15)'",
                                                  modifiers: { "400": true }
                                                }
                                              ],
                                              attrs: { variant: "white" },
                                              on: {
                                                click: function($event) {
                                                  _vm.isEditShiftSidebarActive = false
                                                }
                                              }
                                            },
                                            [_vm._v(" Cancel ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showApplication
                        ? _c(
                            "b-tab",
                            { attrs: { title: "Applications" } },
                            [
                              _c(
                                "b-overlay",
                                {
                                  attrs: {
                                    show: _vm.tableLoading,
                                    rounded: "sm"
                                  }
                                },
                                [
                                  _c("b-table", {
                                    ref: "tableApplication",
                                    staticClass: "mobile_table_css",
                                    attrs: {
                                      "current-page": _vm.currentPage,
                                      fields: _vm.fields,
                                      filter: _vm.filter,
                                      "filter-included-fields": _vm.filterOn,
                                      items: _vm.getUserApplications,
                                      "per-page": _vm.pagination.perPage,
                                      "sort-by": _vm.sortBy,
                                      "sort-desc": _vm.sortDesc,
                                      "sort-direction": _vm.sortDirection,
                                      hover: "",
                                      responsive: ""
                                    },
                                    on: {
                                      "update:sortBy": function($event) {
                                        _vm.sortBy = $event
                                      },
                                      "update:sort-by": function($event) {
                                        _vm.sortBy = $event
                                      },
                                      "update:sortDesc": function($event) {
                                        _vm.sortDesc = $event
                                      },
                                      "update:sort-desc": function($event) {
                                        _vm.sortDesc = $event
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(user)",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "list-inline mb-0"
                                                },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "list-inline-item"
                                                    },
                                                    [
                                                      _c("b-avatar", {
                                                        staticClass: "pull-up ",
                                                        attrs: {
                                                          src: _vm.getUserImage(
                                                            data.item.user_id
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "list-inline-item"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(data.item.user)
                                                      )
                                                    ]
                                                  ),
                                                  data.item.is_locum
                                                    ? _c(
                                                        "b-badge",
                                                        {
                                                          staticClass: "ml-1",
                                                          staticStyle: {
                                                            padding: "8px"
                                                          },
                                                          attrs: {
                                                            variant:
                                                              "light-info"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-dark font-weight-700"
                                                            },
                                                            [_vm._v(" L ")]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(action)",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-tooltip",
                                                          rawName:
                                                            "v-b-tooltip.hover.top",
                                                          value: "Accept",
                                                          expression:
                                                            "'Accept'",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "btn-icon bg-white text-primary ",
                                                      attrs: {
                                                        size: "23",
                                                        variant: "outline-white"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.acceptUserApplication(
                                                            data.item.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("feather-icon", {
                                                        staticClass:
                                                          "text-primary",
                                                        attrs: {
                                                          icon:
                                                            "CheckSquareIcon",
                                                          size: "18"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-tooltip",
                                                          rawName:
                                                            "v-b-tooltip.hover.top",
                                                          value: "Reject",
                                                          expression:
                                                            "'Reject'",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "btn-icon bg-white text-danger ml-1",
                                                      attrs: {
                                                        size: "23",
                                                        variant: "outline-white"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.rejectUserApplication(
                                                            data.item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("feather-icon", {
                                                        staticClass:
                                                          "text-danger",
                                                        attrs: {
                                                          icon: "XSquareIcon",
                                                          size: "18",
                                                          variant:
                                                            "outline-danger"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm.noDataTable === 0
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "text-center",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("No data for preview")
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.swapableType
                        ? _c(
                            "b-tab",
                            { attrs: { title: "Swap request" } },
                            [
                              _c(
                                "div",
                                _vm._l(_vm.swapApplications, function(
                                  swapApplication
                                ) {
                                  return _c(
                                    "b-card",
                                    { key: swapApplication.id },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "8" } },
                                            [
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "list-inline mb-0"
                                                },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "list-inline-item"
                                                    },
                                                    [
                                                      _c("b-avatar", {
                                                        staticClass: "pull-up ",
                                                        attrs: {
                                                          src: _vm.getUserImage(
                                                            swapApplication.shift_user_id
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "list-inline-item ml-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            swapApplication.shift_user
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "1" } },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.top",
                                                      value: "Accept",
                                                      expression: "'Accept'",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true
                                                      }
                                                    }
                                                  ],
                                                  staticClass:
                                                    "btn-icon bg-white text-primary ",
                                                  attrs: {
                                                    size: "23",
                                                    variant: "outline-white"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.acceptUserSwapRequest(
                                                        swapApplication.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    staticClass: "text-primary",
                                                    attrs: {
                                                      icon: "CheckSquareIcon",
                                                      size: "18"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "1" } },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.top",
                                                      value: "Reject",
                                                      expression: "'Reject'",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true
                                                      }
                                                    }
                                                  ],
                                                  staticClass:
                                                    "btn-icon bg-white text-danger ml-1",
                                                  attrs: {
                                                    size: "23",
                                                    variant: "outline-white"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.rejetUserSwapRequest(
                                                        swapApplication.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    staticClass: "text-danger",
                                                    attrs: {
                                                      icon: "XSquareIcon",
                                                      size: "18",
                                                      variant: "outline-danger"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("b-col", {
                                            attrs: { cols: "12", md: "2" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _vm.noDataSwapTable === 0
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("No data for preview")
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }