var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { className: "template-wrap" } }, [
      _c(
        "div",
        { staticClass: "employee-category", class: { active: _vm.isGroup() } },
        [
          _c("div", [
            !_vm.isGroup() && !_vm.isVacant()
              ? _c("img", {
                  staticClass: "employee-image",
                  attrs: { src: _vm.setImage(), alt: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/staff-bank/" + _vm.uid)
                    }
                  }
                })
              : _vm._e(),
            _vm.isVacant()
              ? _c(
                  "svg",
                  {
                    staticClass: "employee-image",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 24 24",
                      width: "24",
                      height: "24"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M12.5 7.25a.75.75 0 00-1.5 0v5.5c0 .27.144.518.378.651l3.5 2a.75.75 0 00.744-1.302L12.5 12.315V7.25z"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        d:
                          "M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zM2.5 12a9.5 9.5 0 1119 0 9.5 9.5 0 01-19 0z"
                      }
                    })
                  ]
                )
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "employee_name  flex",
              staticStyle: {
                "padding-bottom": "1px",
                "padding-top": "2px!important"
              }
            },
            [
              _c("div", { class: { employee: !_vm.isGroup() } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getEmployeeName().slice(0, 25) +
                        (_vm.getEmployeeName().length > 25 ? "..." : "")
                    ) +
                    " "
                )
              ]),
              _vm.isLocum()
                ? _c(
                    "span",
                    {
                      staticClass: " text-dark font-weight-700",
                      staticStyle: {
                        padding: "8px",
                        width: "20px",
                        background: "#CCE6F4",
                        "border-radius": "8px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(" L ")]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }